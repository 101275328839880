<template>
  <div ref="stateWidget">
    <div class="d-flex flex-xl-nowrap flex-lg-wrap align-items-center">
      <b-badge v-if="mode === 0 || mode === 2" :variant="baseStateColor">{{ baseState }}</b-badge>
      <b-badge
        v-if="(mode === 0 || mode === 1 || mode === 2) && workflowState !== null"
        :variant="workflowStateColor"
        class="ml-1"
        >{{ workflowState }}</b-badge
      >
      <div v-if="editState !== null" class="d-flex flex-nowrap align-items-center">
        <b-icon v-if="mode === 1" icon="arrow-right" class="ml-1"></b-icon>
        <b-badge v-if="mode === 1" :variant="editStateColor" class="ml-1 mt-1">{{ editState }}</b-badge>
        <span class="ml-1" v-if="mode === 1 && bearbeiter && displayBearbeiter"
          ><b-icon icon="person-check-fill"></b-icon> {{ bearbeiter }}</span
        >
      </div>
      <b-badge v-else-if="mode === 1" :variant="baseStateColor">{{ baseState }}</b-badge>
    </div>
    <b-popover
      v-if="workflowState !== null"
      :target="() => $refs['stateWidget']"
      triggers="hover"
      placement="top"
    >
      <StateWidgetTable
        :baseState="baseState"
        :workflowState="workflowState"
        :editState="editState"
        :bearbeiter="bearbeiter"
      />
    </b-popover>
  </div>
</template>
<script>
import configStates from '@/core/common/states.config.json';
import { getConfigStringsByObjectType } from '@/core/common/helpers/utils';
import StateWidgetTable from '@/components/common/state-widget-table.vue';
export default {
  components: { StateWidgetTable },
  props: {
    state: { type: Object, required: true },
    mode: { type: Number, default: 0 },
    customState: { type: Object, required: true },
    bearbeiter: { type: String, required: false },
    displayBearbeiter: { type: Boolean, required: false, default: true },
    objType: { type: String, required: true },
  },
  computed: {
    configStrings() {
      return getConfigStringsByObjectType(this.objType);
    },
    cfgStatesByObjType() {
      return configStates[this.objType];
    },
    editState() {
      return this.state.editState !== null && this.state.editState !== undefined
        ? this.$t(this.configStrings.localeKey + '.states.' + this.state.editState)
        : this.state.editState;
    },
    workflowState() {
      return this.state.workflowState !== null && this.state.workflowState !== undefined
        ? this.$t(this.configStrings.localeKey + '.states.' + this.state.workflowState)
        : this.state.workflowState;
    },
    baseState() {
      return this.state.baseState !== null && this.state.baseState !== undefined
        ? this.$t(this.configStrings.localeKey + '.states.' + this.state.baseState)
        : this.state.baseState;
    },
    baseStateColor() {
      return this.state.baseState !== null && this.state.baseState !== undefined
        ? this.cfgStatesByObjType[this.state.baseState].color
        : 'secondary';
    },
    editStateColor() {
      if (this.state.editState !== null && this.state.editState !== undefined) {
        switch (this.customState.stateCategory) {
          case 'Todo':
            return 'primary';
          case 'Doing':
            return 'warning';
          case 'Waiting':
            return 'light';
          default:
            return 'secondary';
        }
      } else {
        return 'secondary';
      }
    },
    workflowStateColor() {
      return this.state.workflowState !== null && this.state.workflowState !== undefined
        ? this.cfgStatesByObjType[this.state.workflowState].color
        : 'secondary';
    },
  },
};
</script>
