<template>
  <table class="table table-sm table-borderless">
    <thead>
      <tr>
        <th class="p-0"></th>
        <th class="p-0"></th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td class="text-muted">Status:</td>
        <td>
          {{ baseState || '-' }}
        </td>
      </tr>
      <tr>
        <td class="text-muted">Prozess:</td>
        <td>
          {{ workflowState || '-' }}
        </td>
      </tr>
      <tr>
        <td class="text-muted">Bearbeitungsschritt:</td>
        <td>
          {{ editState || '-' }}
        </td>
      </tr>
      <tr>
        <td class="text-muted"><b-icon icon="person-check-fill"></b-icon> Bearbeiter:</td>
        <td>
          {{ bearbeiter || '-' }}
        </td>
      </tr>
    </tbody>
  </table>
</template>
<script>
export default {
  props: {
    baseState: { type: String },
    workflowState: { type: String },
    editState: { type: String },
    bearbeiter: { type: String },
  },
};
</script>
